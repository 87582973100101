module.exports = {
  // App Info
  appstore_link: "https://kintimer.app.link/bN2pFH7Nb4", // Enter App Store URL.
  //playstore_link: "https://imedadel.me", // Enter Google Play Store URL.
  google_analytics_ID: "UA-75096825-12", // Enter Google Analytics ID or ""
  //presskit_download_link: "https://imedadel.me", // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file).
  video_or_screenshot: "screenshot", // "screenshot" or "video"
  app_url: "https://kintimer.app", // Domain of your website without path_prefix.
  path_prefix: "/", // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name: "KinTimer: 禁欲计时器",
  app_price: "免费",
  app_description: "通过持续来养成习惯的禁欲、禁烟计数器",
  app_keywords: ["禁欲", "计时器", "禁烟", "计数器"],
  download_label: "下载",
  // Personal Info
  your_name: "",
  your_link: "",
  your_city: "",
  email_address: "helloheydays@gmail.com",
  linkedin_username: null,
  facebook_username: null,
  //instagram_username: "ed_adel",
  twitter_username: "kintimer_appp",
  //github_username: "ImedAdel",
  youtube_username: null,

  // Features List
  features: [
    {
      title: "易用、直观",
      description: "便捷易用！历史记录一目了然",
      fontawesome_icon_name: "sliders-h"
    },
    {
      title: "等级功能",
      description: "通过等级表来确认等级吧。等级随禁欲的天数而提升！",
      fontawesome_icon_name: "medal"
    },
    {
      title: "日志功能",
      description: "自动记录截至目前的禁欲历史。回顾一下自己持续了多久吧！",
      fontawesome_icon_name: "history"
    }
  ],
  header_background: "rgba(0, 0, 0, 0.1)",
  topbar_title_color: "#ffffff",
  cover_overlay_color_rgba: "rgba(54, 59, 61, 0.8)",
  device_color: "black", // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color: "ffffff",
  primary_text_color: "#000",
  content_width: "1170px",
  font: `"Helvetica Neue", sans-serif`,
  link_color: "#1d63ea",
  app_title_color: "#ffffff",
  app_price_color: "#ffffff",
  app_description_color: "#ffffff",
  feature_title_color: "#000000",
  feature_text_color: "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color: "#666666",
  social_icons_background_color: "#e6e6e6",
  footer_text_color: "#666666"
};
